<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        />

        <component 
            :is="collection"
            :status="status"
            :search="search"
            @change_loaddata_status="onLoadDataStatusChanged"
            ref="currentComponent"
        />

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import Svetofor from './lists/Svetofor.vue'
import ReadyToExecuteControl from './lists/ReadyToExecuteControl.vue';
import ExecutedControl from './lists/ExecutedControl.vue';

export default {
    name: "ExecutionControl",
    components: {
        Toolbar,
        FilterPanel,
        Svetofor,
        ReadyToExecuteControl,
        ExecutedControl
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null,
            dataloading:false,
            filterloading:false,
            collectionloading:false,
        }
    },
    computed: {
        filterloadingcomp:{
            get: function() { return this.filterloading; },
            set: function(newValue) { this.filtreloading = newValue; this.refreshOverlay(); }
        },
        collectionloadingcomp:{
            get: function() { return this.collectionloading; },
            set: function(newValue) { this.collectionloading = newValue; this.refreshOverlay(); }
        },
        collection: {
            get: function() {
                return this.$store.getters['executioncontrol/getCollection']
            },
            set: function(newValue) {
                this.$store.commit('executioncontrol/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['executioncontrol/getStatus']
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('executioncontrol/PUSH_STATUS', { collection: this.collection, value: newValue });

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                }
            }
        },
        search: {
            get: function() {
                return this.$store.getters['executioncontrol/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
                this.$store.commit('executioncontrol/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onLoadDataStatusChanged(status){
            this.dataloading = status;
            this.refreshOverlay();    
        },
        refreshOverlay(){
            this.setOverlayVisible({ visible: this.dataloading || this.filterloading || this.collectionloading });
        },
        onToolbarClick(event, btn) {
            this[btn.Action]();
        },
        async loadData() {
            
            this.collectionloadingcomp = true;

            let response = await httpAPI({
                url: `api/actions/collection?type=Documents.ExecutedControl`,
                method: 'GET'
            });
            
            if (response) {            
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }

            this.collectionloadingcomp = false;

        },
        async loadFilters() {
            this.filterloadingcomp = true;
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let filterResponse = await httpAPI({
                url: `api/executioncontrol/filter`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.filterDataSource = filterResponse?.data.payload;
            this.filterloadingcomp = false;
        },
        async Refresh() {

            await this.loadFilters();
            this.$refs.currentComponent.getData(true);

        }
    },
    watch: {
        collection() {

            this.loadData();

        }
    },
    async created() {

        this.loadData();

    }
}
</script>