<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document' })">

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.regdate`]="{ item }">
                    <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.correspondent`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <v-enterprise-chip v-if="item.correspondent" :name="item.correspondent" />
                    </div>
                </template>
                
                <template v-slot:[`item.theme`]="{ item }">
                    <div class="elipsis-text-wrap">                        
                        <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.theme">
                                {{item.theme}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.controldate`]="{ item }">
                    <div>{{dateFormat(item.controldate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.controltype`]="{ item }">
                    <StatusChip :status="avaibleStatuses[$helpers.calculateDocumentStatus(item.controltype, item.controldate, item.execdate, item.hascontrolresolution)]"/>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system"
import StatusChip from '@/components/StatusChip'

export default {
    columnIndex:  {
        'regnumber': 2,
        'regdate': 4,
        'correspondent': 5,
        'theme': 6,
        'controldate': 7
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    components: {
        StatusChip
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Корреспондент"), value: 'correspondent', width: '15%' },
                { text: i18n.t("Краткое_содержание"), value: 'theme'},
                { text: i18n.t("Срок"), value: 'controldate', width: '110px' },
                { text: i18n.t("Статус"), value: 'controltype', width: '150px', sortable: false }
            ],
            total: 0,
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['executioncontrol/readytoexecute/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('executioncontrol/readytoexecute/SET_TABLE_OPTION', newValue);
            }
        },
        collection() {
            return this.$store.getters['executioncontrol/getCollection'];
        },
        filter() {
            return {
                ...this.options,
                SearchStr: this.search,
                ControlDocumentType: this.status
            }
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        }
    },
    methods: {
        itemRowBackground(item) {
            return item.readed === true ? '' : 'font-weight-bold';
        },
        resetPage() {
            this.$store.commit('executioncontrol/readytoexecute/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            this.$emit("change_loaddata_status",this.loading);
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/correspondence/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
            this.$emit("change_loaddata_status",this.loading);
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, ControlDocumentType } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 4;
            let rez = { 
                SearchStr,
                ControlDocumentState: "5",
                ControlDocumentType,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            };
            switch(this.status){
                case "-1": //Любой тип
                    break;
                case "0": //Входящие
                    rez.DocType = 'Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument';
                    rez.DocumentTypeC4 = "0";
                    break;
                case "1": //ДИРОРД
                    rez.DocType = 'Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument';
                    rez.IncomingDocumentType = "2"
                    break;
                case "2": //ОРД
                    rez.DocType = 'Avrora.Objects.Modules.Docflow.DocflowObjects.InnerDocument';
                    rez.DocumentTypeC4 = "1";
                    break;
                case "3": //Пункты ДИРОРД
                    rez.DocType = 'Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument';
                    rez.IncomingDocumentType = "3"
                    break;
            }
            return rez;
        },
        dateFormat: sys.dateFormat,
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
